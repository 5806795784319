import { createApp, defineAsyncComponent } from 'vue'
import App from './App.vue'
import router from './router'
import { VueRecaptchaPlugin } from 'vue-recaptcha'

import NProgress from 'nprogress'
/**
 *  Import custom CSS && JS
 */
import '@/assets/styles.scss'
// import 'animate.css';

/**
 * Import Plugins
 */
import i18n from '@/translation'
import { createPinia } from 'pinia'
import { initInlineSvg } from '@/plugins/inline-svg'
import BootstrapBreakpoints from '@/plugins/BootstrapBreakpoints'
import VueGtag from "vue-gtag";

/**
 * Register Services
 */
import ApiService from '@/services/ApiService'
import createHeadWithHook from '@/plugins/createHeadWithHook'

import * as Sentry from "@sentry/vue";

NProgress.configure({ trickleSpeed: 1200, showSpinner: false })

const { t } = i18n.global

const app = createApp(App)
const head = createHeadWithHook()

app.provide('trans', t)

app.use(createPinia())
app.use(head)
app.use(router)
app.use(i18n)
app.use(BootstrapBreakpoints)
app.use(VueRecaptchaPlugin, {
    v2SiteKey: import.meta.env.VITE_CAPTCHA_V2,
    hl: 'en',
})

app.use(VueGtag, {
  appName: import.meta.env.VITE_APP_NAME,
  pageTrackerScreenviewEnabled: true,
  config: {
    id: import.meta.env.VITE_GA_MEASUREMENT_ID,
  },
}, router);


initInlineSvg(app)

/**
 * Import Global Components
 */

app.component(
    'GuestLayout',
    defineAsyncComponent(() => import('@/components/Layout/GuestLayout.vue'))
)
    .component(
        'AdminLayout',
        defineAsyncComponent(() => import('@/components/Layout/AdminLayout.vue'))
    )
    .component(
        'BaseInput',
        defineAsyncComponent(() => import('@/components/Form/BaseInput.vue'))
    )
    .component(
        'BaseRadio',
        defineAsyncComponent(() => import('@/components/Form/BaseRadio.vue'))
    )
    .component(
        'BaseSelect',
        defineAsyncComponent(() => import('@/components/Form/BaseSelect.vue'))
    )
    .component(
        'PostContent',
        defineAsyncComponent(() => import('@/views/KolAdmin/Components/PostContent.vue'))
    )
    .component(
        'PostContentV2',
        defineAsyncComponent(() => import('@/views/Common/Posts/PostContentV2.vue'))
    )
    .component(
        'GlobalPostContent',
        defineAsyncComponent(() => import('@/views/Common/Posts/GlobalPostContent.vue'))
    )
    .component(
        'PostComment',
        defineAsyncComponent(() => import('@/views/KolAdmin/Components/PostComment.vue'))
    )
    .component(
        'VideoCardLandscape',
        defineAsyncComponent(() => import('@/views/User/Components/VideoCardLandscape.vue'))
    )
    .component(
        'VueMultiselect',
        defineAsyncComponent(() => import('vue-multiselect/src/Multiselect.vue'))
    )
    .component(
        'WorkoutCardItems',
        defineAsyncComponent(() => import('@/views/User/Components/WorkoutCardItems.vue'))
    )
    .component(
        'WorkoutCardItemsV2',
        defineAsyncComponent(() => import('@/views/User/Components/WorkoutCardItemsV2.vue'))
    )
    .component(
        'MasterclassCardItems',
        defineAsyncComponent(() => import('@/views/User/Components/MasterclassCardItems.vue'))
    )
    .component(
        'PodcastCard',
        defineAsyncComponent(() => import('@/views/User/Components/PodcastCard.vue'))
    )
    .component(
        'WorkoutEpisodeItems',
        defineAsyncComponent(() => import('@/views/User/Components/WorkoutEpisodeItems.vue'))
    )
    .component(
        'SelectableWorkoutCard',
        defineAsyncComponent(() => import('@/components/SelectableWorkoutCard.vue'))
    )
    .component(
        'RecipeCardSelector',
        defineAsyncComponent(() => import('@/views/KolAdmin/Recipes/RecipeCardSelector.vue'))
    )
    .component(
        'RecipeCardItems',
        defineAsyncComponent(() => import('@/views/User/Components/RecipeCardItems.vue'))
    )
    .component(
        'PrivateRecipeCardItems',
        defineAsyncComponent(() => import('@/views/User/Recipes/PrivateRecipes/CreatorPrivateRecipeCard.vue'))
    )
    .component(
        'SubscriberConversationCard',
        defineAsyncComponent(() => import('@/views/KolAdmin/Components/Messenger/SubscriberConversationCard.vue'))
    )
    .component(
        'AssetCard',
        defineAsyncComponent(() => import('@/components/AssetCard.vue'))
    )
    .component(
        'MediaCard',
        defineAsyncComponent(() => import('@/views/KolAdmin/Medias/MediaCard.vue'))
    )
    .component(
        'WorkoutProgramCardItems',
        defineAsyncComponent(() => import('@/views/User/Components/WorkoutProgramCardItems.vue'))
    )
    .component(
        'GalleryCardItems',
        defineAsyncComponent(() => import('@/views/User/Components/GalleryCardItems.vue'))
    )
    .component(
        'UnreleasedPost',
        defineAsyncComponent(() => import('@/views/KolAdmin/Components/Posts/UnreleasedPost.vue'))
    )
    .component(
        'LiveEventCard',
        defineAsyncComponent(() => import('@/views/KolAdmin/LiveEvents/LiveEventCard.vue'))
    )

/**
 * Register directives
 */
import BootstrapTooltip from '@/plugins/BootstrapTooltip'
import Intersect from '@/plugins/IntersectDirective'
import VueFilters from '@/helpers/VueFilters'
import AvatarHelper from '@/helpers/AvatarHelper'
import EnvironmentHelper from '@/helpers/EnvironmentHelper'
import vClickOutside from '@/plugins/ClickOutsideDirective'
import VPauseOnHide from "@/plugins/PauseOnHideDirective";
import { directive as imageFullscreen } from 'vue-fullscreen'

app.directive('tooltip', BootstrapTooltip)

app.directive('intersect', Intersect)
app.directive('click-outside', vClickOutside)
app.directive('pause-on-hide', VPauseOnHide)
app.directive('image-fullscreen', imageFullscreen)

app.config.globalProperties.$filters = VueFilters

app.config.globalProperties.$avatar = AvatarHelper
app.config.globalProperties.$env = EnvironmentHelper

ApiService.init(app)

if (import.meta.env.VITE_ENVIRONMENT !== 'local') {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 0.5, //  Capture 50% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^\//, /^https:\/\/.*\.?api\.ikonstar\.com\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

app.mount('#app')
